import React from "react";
import logo from '../assets/logo.png'
import '../App.css'





const Navbar = () => {
    
    return(
        <div className="navbar-main">
            <div className="logo-section">
                <img src={logo} className="logo"></img>
            </div>
            <div className="nav-right">
            <nav class="navbar navbar-expand-lg navbar-light bg-light">
 
 

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class="nav-link" href="#">Home </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#">About</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#">Gallery</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" > </a>
      </li>
   
     
    </ul>
  
  </div>
</nav> </div>

        </div>
    )
}
export default Navbar