import React, {useState} from 'react';
import './App.css';
import Banner from './components/Banner';
import Navbar from './components/Navbar';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";

function App() {
  const [isOpen, setIsopen] = useState(false)
  return (
    <div className="App">
      <Navbar  />
      <Banner />
    </div>
  );
}

export default App;
