import React from "react";
import '../App.css'

const Banner = () => {
    return(
        <div className="main-banner">

      
        <div className="banner-main">
               
        </div>
        <div className="loader">
        <div class="container">
   <div class="box">
    <div class="animation">
     <div class="one spin-one"></div>
     <div class="two spin-two"></div>
     <div class="three spin-one"></div>
    </div>
  <h1>Under maintenance</h1>
  <p>Update in progress.</p>
  <div className="contact-area">
            <h3 className="c1">Email: info@vaayuproductions.com</h3>
            <h3 className="c1">Phone: +91 8770348589 / 8989007494 </h3>
                 </div>
  </div>
                  </div>
        </div>

    
      </div>
    )
}

export default Banner